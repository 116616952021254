import React from 'react'
import Layout from 'screens/shared/Layout'
import Map from 'screens/shared/Map'
import Head from 'components/modules/Head'
import { Flex } from 'components/primitives'
import Text from 'components/core/Text'
import Heading from 'components/core/Heading'
import { graphql } from 'gatsby'
import Link from 'components/core/Link'
import { container } from 'components/core/Layout'

const ExternalLink = props => (
  <Link as='a' color='initial' variant='underline' {...props} />
)
export default ({
  data: {
    site: {
      siteMetadata: { contact }
    }
  }
}) => (
  <Layout {...container} maxWidth={640} py={4}>
    <Head title='방문하기' />
    <Heading fontWeight='heavy' mb={3}>
      방문 전 보고싶은 타일을 미리 알려주시면 보기 편하도록 준비해놓을게요.
    </Heading>
    <Text mb={1}>오픈 시간 월요일부터 금요일 오전 11시부터 오후 6시까지</Text>
    <Text mb={1}>
      이메일{' '}
      <ExternalLink href={`mailto:${contact.email}`}>
        {contact.email}
      </ExternalLink>
    </Text>
    <Flex mb={3}>
      <Text mr='auto'>
        전화{' '}
        <ExternalLink href={`tel:${contact.phone}`}>
          {contact.phone}
        </ExternalLink>
      </Text>
      <ExternalLink mr={4} href='http://naver.me/5GxaUYvS'>
        네이버 지도 열기
      </ExternalLink>
      <ExternalLink href='http://dmaps.kr/dbccn'>다음 지도 열기</ExternalLink>
    </Flex>
    {/* <Map query={contact.address} /> */}
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        contact {
          address
          addressDetail
          email
          phone
        }
      }
    }
  }
`
