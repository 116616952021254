import React from 'react'
import PropTypes from 'prop-types'
import Embed from 'components/core/Embed'

const ZOOM = 16
const REGION = 'kr'
const MODE = 'place'
const PARAMETERS = `&region=${REGION}&zoom=${ZOOM}`
const KEY = 'AIzaSyCb0Kp_05yfvu1L93chEffG6LdA51aLvpE'

// Renders map using Google Embed API.

const Map = ({ query, locale = 'ko', ...props }) => (
  <Embed borderRadius={16} {...props}>
    <iframe
      title='map'
      src={`https://www.google.com/maps/embed/v1/${MODE}?q=${query}&language=${locale}&key=${KEY}${PARAMETERS}`}
    />
  </Embed>
)

Map.propTypes = {
  query: PropTypes.string.isRequired
}

export default Map
