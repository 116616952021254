import styled from '@emotion/styled'
import { Card } from 'components/primitives'

const Embed = styled(Card)`
  position: relative;
  overflow: hidden;

  > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    border: 0;
  }
`

Embed.defaultProps = {
  height: 0,
  pb: `${(9 / 16) * 100}%`
}

export default Embed
